import { Checkbox, MenuItem, Select, Stack, Typography, FormControlLabel, TextField, Paper, Button } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { theme } from '../../../theme';
import TextingConfirmationDialog from './TextingConfirmationDialog';
import TextingPreviewSummary from './TextingPreviewSummary';
import { useCreateBulkMessageJobMutation } from '../../../api/campaignSlice';
import { useParams } from 'react-router-dom';


function BulkTexting() {
    const { campaignId } = useParams();
    const [textType, setTextType] = useState('none');
    const [includeLink, setIncludeLink] = useState(false);
    const [message, setMessage] = useState('');
    const [isShowingConfirmation, setIsShowingConfirmation] = useState(false);
    const [createBulkTextMessage] = useCreateBulkMessageJobMutation();

    function handleMessageTypeChange(value) {
        if (value === 'kickoff') {
            setIncludeLink(true);
        }
        setTextType(value);
    }

    function handleAddVariable(type) {
        if (type === 'surgeon_name') {
            setMessage(message + '[[surgeon_last_name]]');
        }
        if (type === 'savings') {
            setMessage(message + '[[savings_opportunity]]');
        }
    }

    const handleSendTextMessage = async() => {
        await createBulkTextMessage({
            campaignId: campaignId,
            requestBody: {
                message_type: textType,
                message_text: message,
                include_link: includeLink,
            }
        });
        setIsShowingConfirmation(false);
    }

    return (
        <Stack direction='row' mx={3} justifyContent='space-between'>
            <Paper elevation={3} sx={{ width: '60%' }}>
                <Stack spacing={4} p={4}>
                    <Stack spacing={2}>
                        <Typography variant='heavy' fontSize='20px'>Send Bulk Campaign Text</Typography>
                        <Stack spacing={1}>
                            <Typography variant='normal' fontSize='16px'>Select a type of text message to send:</Typography>
                            <Select variant='outlined' value={textType} onChange={(e) => handleMessageTypeChange(e.target.value)}>
                                <MenuItem value='none'>Select a message type</MenuItem>
                                <MenuItem value='kickoff'>Campaign Kickoff Text</MenuItem>
                                <MenuItem value='follow-up'>Campaign Follow-Up Text</MenuItem>
                            </Select>
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        <TextField
                            label='Message'
                            multiline
                            minRows={4}
                            variant='outlined'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            fullWidth
                        />
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <FormControlLabel 
                                control={<Checkbox checked={includeLink} disabled={textType === 'kickoff'} onChange={() => setIncludeLink(!includeLink)} />} 
                                label={
                                    <Stack>
                                        <Typography variant='normal' fontSize='14px'>Include physician dashboard link?</Typography>
                                        { textType === 'kickoff' && 
                                            <Typography variant='normal' fontSize='12px' color={theme.palette.red.main}>Links will always be included in kickoff texts.</Typography>
                                        }
                                    </Stack>
                                }
                                name='link'
                            />
                            <Stack direction='row' spacing={2}>
                                <Button variant='contained' color='primary' onClick={() => handleAddVariable('surgeon_name')}>Add Surgeon Last Name Variable</Button>
                                <Button variant='contained' color='primary' onClick={() => handleAddVariable('savings')}>Add Savings Opportunity Variable</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Button variant='contained' color='primary' disabled={textType === 'none' || message === ''} onClick={() => setIsShowingConfirmation(true)}>Send Text</Button>
                    <TextingConfirmationDialog open={isShowingConfirmation} onCancel={() => setIsShowingConfirmation(false)} onConfirm={handleSendTextMessage} />
                </Stack>
            </Paper>
            <Paper elevation={3} sx={{ width: '35%' }}>
                <TextingPreviewSummary campaignId={campaignId} textType={textType} />
            </Paper>
        </Stack>
    )
}


export default BulkTexting;
